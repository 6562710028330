@import "./shared.scss";

.header-section {
    background: white;

    @include iPad {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas:
            "secondary"
            "primary";
    }

    .primary-container {
        height: 57px;
        border-bottom: 1px solid  $transparent-gray;
        grid-area: primary;

        @include iPad {
            height: 78px;
        }
    }

    .secondary-container {
        height: 60px;
        border-bottom: 1px solid  $transparent-gray;
        grid-area: secondary;

        @include iPad {
            height: 48px;
        }
    }

    .primary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 57px;

        @include iPad {
            justify-content: left;
            height: 78px;
        }
    
        .header-left-group {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 72%;
            max-width: 234px;
            height: 100%;

            @include iPad {
                max-width: 370px;
            }

            .burger-menu-open {
                cursor: pointer;

                @include iPad {
                    display: none;
                }
            }
    
            .burger-menu-open > div {
                width: 22px;
                height: 2px;
                background-color: black;
                margin: 6px 0;
            }

            .burger-menu-close {
                cursor: pointer;
                width: 23px;
                height: 23px;
                position: relative;

                @include iPad {
                    display: none;
                }
            }

            .burger-menu-close > div {
                width: 23px;
                height: 2px;
                background-color: black;
                margin-top: -2px;
                position: absolute;
                top: 50%;
            }

            .burger-menu-close > div:nth-child(1) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            .burger-menu-close > div:nth-child(2) {
                visibility: hidden;
            }

            .burger-menu-close > div:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
    
            #logo {
                width: 174px;
                height: 16px;
            }

            .descriptor {
                display: none;

                @include iPad {
                    padding-top: 2px;
                    display: inline;
                    font-family: Open Sans;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .header-center-group {
            display: none;
            font-family: 'Open Sans';
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            width: 332px;
            height: 100%;

            .menu-link {
                height: 100%;
                color: #302F3A;
            }

            .menu-link-area {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            @include desktop {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 115px;
            }
        }
    
        .header-right-group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;

            @include iPad {
                display: none;
            }
    
            #phone-icon {
                width: 32px;
                height: 32px;
            }
        }
    }

    .secondary {
        padding-top: 13px;
        font-family: 'Open Sans';
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include iPad {
            padding-top: 0;
        }

        .information {
            display: block;
            font-family: 'Open Sans';
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #515159;
            max-width: 486px;
            height: 100%;

            a {
                display: none;

                @include iPad {
                    display: inline;
                }
            }

            @include iPad {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .information-icon {
                @include iPad {
                    margin-left: 1.1vw;
                }

                @include desktop {
                    margin-left: 2.1vw;
                }
            }

            .information-title {
                margin-left: .5vw;
                margin-right: 1.1vw;
                white-space: nowrap;
                color: #515159;

                @include desktop {
                    margin-right: 2.1vw;
                }
            }

            .location-title {
                margin-left: 2vw;
                font-size: 14px;

                @include iPad {
                    margin-left: .5vw;
                    font-size: inherit;
                }

                @include desktop {
                    margin-right: .8vw;
                }
            }

            .regions-dropdown-close {
                display: none;
                margin-right: 2.1vw;

                @include desktop {
                    display: inline;
                }
            }

            .regions-dropdown-open {
                display: none;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                transform: rotate(180deg);
                margin-right: 2.1vw;

                @include desktop {
                    display: inline;
                }
            }

            .information-link {
                height: 100%;
            }

            .location {
                display: flex;
                justify-content: left;
                align-items: center;
                height: 100%;
                cursor: pointer;

                @include iPad {
                    border-left: 1px solid  $transparent-gray;
                    border-right: 1px solid  $transparent-gray;
                }
            }
    
            .partners {
                display: flex;
                justify-content: left;
                align-items: center;
                height: 100%;
                border-right: 1px solid  $transparent-gray;
            }
    
            .office {
                display: flex;
                justify-content: left;
                align-items: center;
                height: 100%;
                border-right: 1px solid  $transparent-gray;
            }
        }

        .contacts {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include iPad {
                width: 41vw;
            }

            @include desktop {
                max-width: 455px;
            }

            .number-and-label {
                display: none;

                @include iPad {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                }
            }
    
            .number {
                font-weight: 600;
                font-size: 16px;
            }
    
            .label {
                visibility: hidden;
                width: 5px;
                height: 5px;
                border-radius: 5px;
                margin-left: 4px;
                background-color: $green;
    
                @include iPhone-X {
                    visibility: visible;
                }
    
                @include desktop {
                    display: block;
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    border-radius: 10px;
                    padding: 2px 7px 2px 7px;
                    width: 102px;
                    height: 18px;
                    margin-left: 10px;
                    color: white;
                }
            }
    
            .label-text {
                display: none;
    
                @include desktop {
                    display: inline;
                }
            }
    
            .callback-button {
                display: inline-block;
                color: #626364;
                background-color: #F0F0F0;
                text-align: center;
                width: 138px;
                padding: 9px 0 9px 0;
                border-radius: 4px;
                font-weight: 600;
                font-size: 12px;
    
                @include desktop {
                    width: 158px;
                    height: 34px;
                }
            }
        }
    }
}