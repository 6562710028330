@import "./shared.scss";

.houses-title-section {
    margin-top: 50px;

    @include iPad {
        background-color: white;
        padding: 50px 0;
    }
}
