@import "./shared.scss";

.cv-section {
    margin-top: 43px;

    @include iPad {
        margin-top: 80px;
    }

    .section-content {
        @include desktop {
            display: grid;
            grid-gap: 25px;
            grid-template-columns: 1fr 348px;
        }
    }

    .container {
        margin-top: 25px;

        @include iPad {
            background-color: white;
            width: 100%;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
            border-radius: 12px;
        }
    }

    .content-wrapper {
        @include iPad {
            padding: 0 25px 0 25px;
        }
    }

    .cv-list {
        @include iPad {
            margin-top: 0;
            padding-top: 5px;
            display: table;
            width: 100%;
        }
    }

    .table-header {
        display: none;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 28px;
        opacity: 0.3;

        @include iPad {
            display: table-row;
            height: 52px;
        }
    }

    .table-header-block {
        @include iPad {
            display: table-cell;
            vertical-align: middle;
        }
    }

    .remoteness {
        display: none;

        @include iPad {
            display: table-cell;
            vertical-align: middle;
        }
    }

    .table-header-block:first-child .column-title {
        padding-left: 14px;
    }

    .cv-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        margin-bottom: 14px;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        min-height: 89px;

        @include iPad {
            display: table-row;
            box-shadow: none;
            height: 52px;
        }
    }

    .cv-item:nth-child(odd) > div {
        @include iPad {
            background-color: #F6F7F9;
        }
    }

    .cv-item > div:first-child {
        @include iPad {
            border-radius: 8px 0 0 8px;
        }
    }

    .cv-item > div:last-child {
        @include iPad {
            border-radius: 0 8px 8px 0;
        }
    }

    .cv-text-block {
        margin-left: 16px;

        @include iPad {
            display: table-cell;
            padding-left: 14px;
            vertical-align: middle;

            h3 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .cv-remoteness-block {
        display: none;

        @include iPad {
            display: table-cell;
            vertical-align: middle;
            white-space: nowrap;
        }

        .minutes {
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;

            @include desktop {
                font-size: 12px;
            }

            @include desktop-1060 {
                font-size: 15px;
            }
        }

        .area {
            display: inline-block;
            margin-left: 5px;
            padding: 0 12px;
            border: 1px solid  $transparent-gray;
            box-sizing: border-box;
            border-radius: 6px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 30px;
            color: #302F3A;
            opacity: 0.7;

            @include desktop {
                font-size: 8px;
                padding: 0 8px;
            }

            @include desktop-1060 {
                font-size: 12px;
                padding: 0 12px;
            }
        }
    }

    .cv-icons-block {
        margin-right: 16px;
        margin-bottom: 16px;
        width: 68px;
        justify-content: space-evenly;

        @include iPad {
            display: table-cell;
            
            width: 178px;
            vertical-align: middle;
        }
    }

    .cv-title {
        font-weight: 600;
        font-size: 15px;
        line-height: 28px;
    }

    .cv-description {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 12px;
        line-height: 28px;
        opacity: 0.3;

        @include iPad {
            display: none;
        }
    }

    .cv-icon {
        padding-top: 16px;
        padding-left: 14px;

        @include iPad {
            padding-top: 0;
            padding-left: 0;
            padding-right: 12px;
        }
    }

    .cv-see-more-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include iPad {
            border-top: 1px solid  $transparent-gray;
            margin-top: 19px;
            padding: 29px 0 36px 0;
            justify-content: left;
        }
    }

    .cv-see-more-button {
        display: inline-block;
        text-align: center;
        color: white;
        width: 50%;
        background-color: $green;
        padding: 11px 0 12px 0;
        border-radius: 4px;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;

        @include iPad {
            width: 186px;
        }
    }

    .cv-see-more-text {
        text-align: right;
        margin-right: 1%;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        opacity: 0.4;

        @include iPad {
            margin-left: 34px;
        }
    }

    .showcase-house-block {
        background-color: white;
        height: 411px;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        text-align: center;
        max-width: 375px;
        margin: 56px auto 0 auto;

        @include desktop {
            width: 348px;
            margin: 25px 0 0 0;
        }
        
        .image {
            width: 100%;
            border-radius: 10px;
            height: 247px;
            background: url('../images/klever-house.jpg');
            background-size: cover;
            background-position: center;
        }

        .title {
            margin-top: 22px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }

        .button {
            display: inline-block;
            margin-top: 22px;
            text-align: center;
            color: #626364;
            width: 65%;
            background-color: #E8E8E8;
            padding: 11px 0 12px 0;
            border-radius: 4px;
            font-family: 'Open Sans';
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
        }
    }
}