@import "shared.scss";

.showhouse-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    transition: opacity 300ms;
    visibility: hidden;
    opacity: 0;

    .modal-content {
        height: 100%;
        overflow: scroll;
    }

    .container {
        width: 100%;
        position: absolute; 
        bottom: 0px;
        background-color: white;
        border-radius: 20px 20px 0 0;
        z-index: 3;

        @include desktop {
            width: 932px;
            height: 523px;
            border-radius: 12px;
            left: 0; 
            right: 0; 
            top: 0;
            bottom: 0;
            margin-left: auto; 
            margin-right: auto; 
            margin-top: auto;
            margin-bottom: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .main {
        padding-left: 5%;
        padding-right: 5%;

        @include desktop {
            width: 539px;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            justify-content: center;
        }

        .main-content {
            @include desktop {
                width: 374px;
            }
        }

        .header-block {
            display: flex;
            justify-content: flex-end;
        }
    
        .modal-close-icon {
            margin-top: 5%;

            @include desktop {
                display: none;
            }
        }
    
        .texts-block {
            margin-top: -5%;
            width: 80%;
        }
    
        .modal-title {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: #3F3E48;

            @include desktop {
                font-weight: 700;
                font-size: 34px;
                line-height: 42px;
                color: #302F3A;
                width: 374px;
            }
        }
    
        .modal-text {
            margin-top: 14px;
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 22px;
            opacity: 0.6;
            max-width: 300px;

            @include desktop {
                margin-top: 20px;
            }
        }
    
        .modal-form {
            font-family: 'Open Sans';
            font-size: 16px;
            line-height: 30px;
            margin-top: 30px;
            margin-bottom: 20px;

            @include desktop {
                margin-top: 40px;
                width: 374px;
                margin-bottom: 0px;
            }
        }
    
        .modal-form-input  {
            font-family: 'Open Sans';
            font-size: 16px;
            height: 52px;
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #ECECEC;
            box-sizing: border-box;
            border-radius: 7px;
            font-size: 16px;
            line-height: 30px;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            margin-bottom: 10px;
            text-indent: 18px;
            color: #000000;
        }
    
        .modal-form-input::placeholder {
            color: #000000;
            opacity: 0.3;
        }
    
        .modal-form-textarea::placeholder {
            color: #000000;
            opacity: 0.3;
        }
    
        .modal-form-button {
            font-family: 'Open Sans';
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            margin-top: 30px;
            height: 63px;
            width: 100%;
            background: $green;
            border-radius: 4px;
            color: white;
            border: none;
            padding: 0;
            cursor: pointer;
            outline: inherit;
        }

        .main-success {
            text-align: center;
        }

        .main-success .modal-title {
            margin-top: 39px;
        }

        .main-success .modal-text {
            margin-top: 2px;
            max-width: 100%;
        }

        .model-return-button {
            margin-top: 49px;
            margin-bottom: 20px;
            display: block;
            background-color: $green;
            border-radius: 4px;
            width: 100%;
            color: white;
            font-family: 'Open Sans';
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            padding: 21px 0 20px 0;
        }
    }

    .decoration {
        display: none;
        height: 523px;
        width: 393px;
        background: #F6F7F9;
        border-radius: 0 12px 12px 0;

        @include desktop {
            display: block;
        }

        .header-block {
            display: flex;
            justify-content: flex-end;
        }
    
        .modal-close-icon {
            margin-top: 5%;
            margin-right: 5%;
        }

        .texts-block {
            width: 100%;
            position: relative;
            margin-top: 102px;
        }

        .decoration-text {
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 52px;
            display: block;
            padding-left: 33px;
            width: 264px;
            height: 53px;
            border-radius: 8px;
            background-color: white;
            color: #302F3A;
            margin: 20px auto;
        }
    }

    .close-on-bg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: block;
    }
}

.showhouse-modal:target {
    visibility: visible;
    opacity: 1;
    z-index: 2;
}
