@import "shared.scss";

.partners-section {
    background-color: white;
    border-bottom: 1px solid  $transparent-gray;
    padding-top: 32px;
    padding-bottom: 40px;

    @include iPad {
        padding-top: 71px;
        padding-bottom: 83px;
    }


    .container {
        @include iPad {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .text-block {
        max-width: 294px;
    }

    .description {
        margin-top: 2px;
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        opacity: 0.6;

        @include iPad {
            margin-top: 12px;
            font-size: 15px;
        }
    }

    .logos-block {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));

        @include iPad {
            width: 70%;
            padding-left: 20px;
        }
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85px;
        background: white;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }

    .logo-image {
        height: 50px;
    }
}