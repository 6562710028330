@import "shared.scss";

.contacts-section {
    background-color: white;
    border-bottom: 1px solid  $transparent-gray;

    .container {
        padding-top: 23px;
        padding-bottom: 40px;

        @include iPad {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @include desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .phone-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            width: 60%;
        }

        @include desktop-1060 {
            width: 55%;
        }
    }

    .phonenumber-block{
        width: 50%;

        @include iPad {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 315px;
        }
    }

    .phone {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: calc(min(5vw, 20px));
        line-height: 27px;

        @include iPad {
            font-size: 24px;
        }
    }

    .online {
        display: block;
        max-width: 93px;
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 9px;
        line-height: 12px;
        border-radius: 10px;
        background-color: $green;
        padding: 2px 7px 2px 7px;
        color: #FFFFFF;

        @include iPad {
            font-size: 10px;
            max-width: 102px;
            padding: 2px 7px 2px 7px;
        }
    }

    .callback-button {
        display: inline-block;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: calc(min(3vw, 13px));
        line-height: 18px;
        padding: 12px 16px;
        color: #626364;
        background-color: #F0F0F0;
        border-radius: 4px;

        @include desktop {
            margin-right: 20px;
        }

        @include desktop-1060 {
            margin-right: 40px;
        }
    }

    .subscribe-form {
        margin-top: 31px;
        display: flex;
        justify-content: center;

        @include desktop {
            margin-top: 0;
            width: 40%;
        }

        @include desktop-1060 {
            width: 45%;
        }
    }

    #email-input {
        width: 50%;
        height: 42px;
        background: #FFFFFF;
        border: 1px solid #ECECEC;
        box-sizing: border-box;
        border-radius: 4px 0 0 4px;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: calc(min(3vw, 13px));
        line-height: 18px;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;

        @include desktop {
            width: 50%;
        }
    }

    #subscribe-button {
        height: 42px;
        width: 50%;
        background: #F0F0F0;
        border-radius: 0 4px 4px 0;
        color: #626364;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: calc(min(3vw, 13px));
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;

        @include desktop {
            width: 50%;
        }
    }
}