@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://rawcdn.githack.com/JulietaUla/Montserrat/master/fonts/webfonts/Montserrat.css");

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
  height: 100%;
}

body {
  margin: 0;
  background: #F6F7F9;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased; /* react */
  -moz-osx-font-smoothing: grayscale; /* react */
}

a {
  color: #000000;
  text-decoration: none;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
