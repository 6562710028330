@import "shared.scss";

.technologies-section {
    margin-top: 32px;

    @include iPad {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    .container {
        @include iPad {
           display: grid;
           grid-template-columns: 1fr 1fr;
           grid-template-areas:
               "text-block images-gallery"
               "switchers images-gallery";
        }
    }

    .text-block {
        @include iPad {
            grid-area: text-block;
            align-self: end;
            justify-self: start;
            padding-top: 20px;
        }
    }

    .description {
        margin-top: 10px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        opacity: 0.6;

        @include iPad {
            font-size: 12px;
            padding-top: 20px;
            width: 300px;
        }

        @include desktop {
            font-size: 15px;
            padding-top: 20px;
            width: 400px;
        }
    }

    .slider {
        margin-top: 15px;
        background-color: white;
        width: 100%;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
        border-radius: 18px 18px 0 0;
        
        @include iPad {
            margin-top: 0;
            margin-left: 0;
            grid-area: images-gallery;
            justify-self: end;
            max-width: 661px;
            max-height: 532px;
        }
    }

    .swiper-container {
        width: 100%;

        @include desktop-1060 {
            height: 528.09px;
        }
    }

    .swiper-wrapper {
        margin-top: 30px;
        width: 286.82px;

        @include desktop-1060 {
            height: 386.82px;
        }
    }

    .swiper-wrapper > .swiper-slide:nth-child(1) {
        background-image: url('../images/tech-1.png');
    }

    .swiper-wrapper > .swiper-slide:nth-child(2) {
        background-image: url('../images/tech-2.png');
    }

    .swiper-wrapper > .swiper-slide:nth-child(3) {
        background-image: url('../images/tech-3.png');
    }

    .swiper-slide {
        width: 287px;
        height: 370.06px;
        text-align: center;
        background-size: contain;
        background-position: bottom;
        image-rendering: -webkit-optimize-contrast;

        @include desktop-1060 {
            width: 387px;
            height: 499.09px;
        }
    }

    .switchers-container {
        display: flex;
        justify-content: center;

        @include iPad {
            grid-area: switchers;
            justify-content: left;
            align-self: start;
            justify-self: start;
            padding-top: 40px;
        }
    }

    .switchers {
        margin-top: 43px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 51px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        @include iPad {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .switchers:focus {
        outline: none;
    }

    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background: #DCDCDC;
        border-radius: 16px;
        margin-right: 20px;
        opacity: 1;
        cursor: pointer;
    }

    .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background: $green;
        border-radius: 16px;
        margin-right: 20px;
        opacity: 1;
        cursor: pointer;
    }
}