@import "shared.scss";

.gallery-section {
    .houses-gallery {
        margin-top: 17px;
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */

        @include iPad {
            margin-top: 0;
        }
    }

    .houses-gallery::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    .gallery-item {
        display: inline-block;
    }

    .picture {
        width: 265px;
        height: 187px;

        @include iPad {
            width: 330px;
            height: 233px;
        }
    }
}