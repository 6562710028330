@import "./shared.scss";

.drop-down-menu {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    transition: opacity 300ms;
    visibility: hidden;
    opacity: 0;

    @include iPad {
        display: none;
    }

    .container {
        width: 100%;
        background-color: white;
    }

    .content {
        border-bottom: 1px solid  $transparent-gray;
        font-family: 'Open Sans';
        font-weight: 600;

        .content-block-title {
            display: flex;
            justify-content: left;
            align-items: center;
            width: 210px;
        }

        .pin {
            margin-left: 2px;
            margin-right: 17px;
        }

        .clock {
            margin-right: 13px;
        }

        .content-subtitle {
            font-size: 15px;
            line-height: 20px;
            color: #515159;
        }

        .content-text {
            font-size: 12px;
            line-height: 24px;
            color: #515159;
            margin-top: 20px;
            margin-left: 35px;
        }

        .address-block {
            padding-top: 28px;
        }

        .timetable-block {
            margin-top: 30px;
            margin-bottom: 25px;
        }

        .timetable {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            width: 210px;
        }
    }

    .social {
        .social-icons-block {
            padding: 25px 0;
        }

        .social-icon {
            margin-right: 4vw;
        }
    }
}

.drop-down-menu:target {
    visibility: visible;
    opacity: 1;
    z-index: 2;
}
