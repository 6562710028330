@import "./shared.scss";

.drop-down-regions {
    position: relative;
    background-color: white;
    width: 232px;
    box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.05);
    z-index: 2;

    @include iPad {
        margin-top: -10px;
    }

    .regions-block {
        margin-top: 10px;
        padding-bottom: 20px;
    }

    .region {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .region-title {
        margin-top: 20px;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #515159;

        @include iPad {
            font-size: 12px;
        }
    }

    .coming-label {
        display: inline-block;
        background: #EDEDED;
        padding: 2px 7px;
        border-radius: 8px;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #515159;
    }
}